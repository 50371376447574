import React, { useState } from 'react';
import { useEffectOnce } from "react-use";
import Tools from "../lib/tools";
import './index.scss';

import TwelveMeridiansImg from '../assets/images/twelve-meridians.png';
import EightPlusImg from '../assets/images/eight-plus.png';
import StrangePointImg from '../assets/images/strange-point.png';
import MeridianDetailImg from '../assets/images/meridian-detail.png';
import LogoTitleImg from '../assets/images/logo-title.png';
import AndroidImg from '../assets/images/android.png';
import IosImg from '../assets/images/ios.png';
import ArrowImg from '../assets/images/arrow.png';

function Main() {
    const iosUrl = 'itms-apps://itunes.apple.com/app/id1554030035'
    const [isWeChat, setIsWechat] = useState(false);
    const [isIos, setIsIos] = useState(false);

    useEffectOnce(() => {
        // 是微信且非ios
        setIsWechat(Tools.isWeChat() && !Tools.isIos());
        setIsIos(Tools.isIos())
    })

    function download() {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        let url = `https://app-res.lighttcm.com/apk/%E4%B8%AD%E6%B5%8E%E7%BB%8F%E7%BB%9C%E7%A9%B4%E4%BD%8D%20latest${params.channel ? `.${params.channel}` : ""}.apk`
        if (isIos) url = iosUrl;
        window.location.href = url;
    }

    return (
        <div className="content">
            <div className="default-main">
                <div className="detail">
                    <div>
                        <img className="twelve-meridians" src={TwelveMeridiansImg} alt="十二经络" />
                        <img className="eight-plus" src={EightPlusImg} alt="奇经八脉" />
                        <img className="meridian-detail" src={MeridianDetailImg} alt="经络详情" />
                        <img className="strange-point" src={StrangePointImg} alt="经外奇穴" />
                    </div>
                </div>
                <div className="logo-title">
                    <img src={LogoTitleImg} alt="经络穴位" />
                </div>
                <div className="tips">
                    <p>经络知识</p>
                    <p>进针动画</p>
                    <p>穴位搜索</p>
                    <p>专属客服</p>
                </div>
                <div className="action-wrap">
                    <div onClick={download}>
                        <img src={isIos ? IosImg : AndroidImg} alt="点击下载" />&ensp;
                        <p>点击下载</p>
                    </div>
                </div>
                <div className="footer">
                    <p>版权所有Copyright © 2021重庆知源健康管理有限公司</p>
                    <p>All Rights Reserved 渝ICP备2021007722号</p>
                </div>
            </div>
            {
                isWeChat && <div className="mask">
                    <div>
                        <h4>点击右上角[更多]，使用浏览器打开</h4>
                        <img src={ArrowImg} alt="浏览器打开" />
                    </div>
                </div>
            }
        </div>
    )
}

export default Main;