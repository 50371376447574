import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
// HashHistory
import {createHashHistory} from 'history';
import Main from './main';
import "./index.scss";

// 全局构建history
global.History = createHashHistory();

function App() {
    return (
        <Router history={global.History}>
            <Switch>
                <Route path="/" component={Main}/>
            </Switch>
        </Router>
    );
}

export default App;
